<template>
    <div class="device-item">
      <el-tabs class="tabs" v-model="fullPath" @tab-click="onLink">
        <el-tab-pane v-for="item in salesObj" :key="item.path" :label="item.title" :name="item.path">
        </el-tab-pane>
      </el-tabs>
      <router-view :key="$route.fullPath"></router-view>
    </div>
  </template>
  
  <script>
  import { salesObj } from "@/db/objs"
  export default {
    data() {
      return {
        salesObj,
        fullPath: this.$route.path
      };
    },
    methods: {
      onLink() {
        if (this.$route.path != this.fullPath) {
          this.$router.push({
            path: this.fullPath,
            query: this.$route.query
          });
        }
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .device-item{
    background: #f0f2f5;
    height: 100%;
  }
  .tabs{
    padding: 0 .15rem;
    background: #fff;
  }
  </style>